
import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import SubSkillTableHeader from './SubSkillTableHeader';
import { BaseTableRQ } from 'components/common/models/BaseClass';
import { search } from 'services/skill-matric-services/smSubSkillService';
import PageHeader from 'components/common/PageHeader';
import SubSkillTable from './SubSkillTable';
import SubSkillModal from './SubSkillModal';


const SubSkillDashboard = () => {
  const defaultRQ = { ...new BaseTableRQ(1, 5) };
  const [levels, setLevels] = useState([]);
  const [total, setTotal] = useState(0);
  const [paging, setPaging] = useState({ ...defaultRQ });
  const [isFilter, setIsFilter] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [dataModal, setDataModal] = useState({});

  useEffect(() => {
    setIsFilter(false);
  }, []);

  const gotoPage = index => {
    index += 1;
    if (index <= 0) return;
    paging.pageNum = index;
    loadData(paging);
  };

  const toggelFilter = () => {
    setIsFilter(!isFilter);
  };
  const toggelModal = () => {
    setIsModal(!isModal);
    setDataModal({});
  };

  const nextPage = () => {
    const page = paging.pageNum + 1;
    if (page > Math.ceil(total / paging.pageSize)) return;
    paging.pageNum = page;
    loadData(paging);
  };

  const previousPage = () => {
    const page = paging.pageNum - 1;
    if (page <= 0) return;
    paging.pageNum = page;
    loadData(paging);
  };

  const handleCloseModal = () => {
    setIsModal(false)
    setDataModal(null);
  }
  const handleSubmitModal = () => {
    handleCloseModal();
    loadData(defaultRQ);
  }

  useEffect(() => {
    loadData(defaultRQ);
  }, []);

  const loadData = pagingRq => {
    search(pagingRq)
      .then(res => {
        if (res) {
          setLevels(res.items);
          setTotal(res.total);
        }
      })
      .catch(err => {
        console.log(err);
      });
    setPaging(pagingRq);
  };

  const handleSeach = keyword => {
    loadData({ ...defaultRQ, keyword });
  };

  return (
    <>
      <PageHeader
        title="Sub Skill"
        description={`Manage and view all available Sub Skill from this page.`}
        className="mb-3"
      ></PageHeader>
      <Row className="g-3 mb-3">
        <Col lg={12}>
          <Card>
            <Row style={{ padding: '10px' }}>
              <SubSkillTableHeader
                toggelFilter={() => toggelFilter()}
                toggelModal={() => toggelModal()}
                handleSearch={handleSeach}
                style={{ padding: '10px' }}
              />
              <Col lg={isFilter ? 9 : 12}>
                <SubSkillTable
                  data={levels}
                  paging={paging}
                  total={total}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  gotoPage={gotoPage}
                  handleClickRow={(row) => {
                    console.log(row)
                    setDataModal(row.original);
                    setIsModal(true);
                  }}
                />
              </Col>
              {/* 
              {isFilter && (
                <Col lg={3}>
                  <ActionPlanFilter
                    handleChange={handleChange}
                    formData={filter}
                    handleSubmit={handleSubmit}
                  />
                </Col>
              )} */}
            </Row>
          </Card>
        </Col>
      </Row>
      <SubSkillModal isShow={isModal} handleClose={handleCloseModal} data={dataModal} handleSubmit={handleSubmitModal} />
    </>
  );
};

SubSkillDashboard.propTypes = {
};

export default SubSkillDashboard;
