import { Card, Form, Row, Col, Button } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { departments } from 'data/TempData';
import UserDropdownV2 from 'components/Dropdowns/CustomerServices/UserDropdownV2';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DepartmentDropdown from 'components/Dropdowns/CustomerServices/DepartmentDropdown';
import InputDatePicker from 'components/pages/customer-service/action-plans/create-action-plan/InputDatePicker';
import SmSectionDropdown from 'components/Dropdowns/SkillMatrix/SmSectionDropdown';
import SmAreaDropdown from 'components/Dropdowns/SkillMatrix/SmAreaDropdown';
import SmSkillLevelDropdown from 'components/Dropdowns/SkillMatrix/SmSkillLevelDropdown';
import SmSkillActivityDropdown from 'components/Dropdowns/SkillMatrix/SmSkillActivityDropdown';

const TraningRecordFilter = ({
  formData,
  handleChange,
  handleSubmit,
  handleClear
}) => {
  const [data, setData] = useState({});
  useEffect(() => {
    setData(formData);
  }, [formData]);

  return (
    <Card>
      {/* <Card.Header as="h5">Fitler</Card.Header> */}
      <Card.Body className="bg-light">
        <Row>
          <Col md="12">
            <Form.Group controlId="eventTitle">
              <Form.Label>Keyword</Form.Label>
              <Form.Control
                type="text"
                name="keyword"
                placeholder="Keyword"
                onChange={e => handleChange('keyword', e.target.value)}
                value={data?.keyword ?? ""}
              />
            </Form.Group>
          </Col>
          <Col md="6" style={{ marginTop: '7px' }}>
            <InputDatePicker
              column="startDate"
              displayName="Start Date"
              selected={data?.startDate}
              handleChange={handleChange}
            />
          </Col>
          <Col md="6" style={{ marginTop: '7px' }}>
            <UserDropdownV2
              label="Trainee"
              fieldName="trainee"
              handleChange={handleChange}
              placeholder="Trainee..."
              value={data?.trainee ?? ""}
            />
          </Col>
          <Col md="6" style={{ marginTop: '7px' }}>
            <Flex alignItems="center" justifyContent="between">
              <Form.Label>Section</Form.Label>
            </Flex>
            <SmSectionDropdown
              fieldName="sectionId"
              isMulti={false}
              selectedOptions={data?.sectionId ?? ""}
              handleChange={handleChange}
              placeholder="Section"
            />
          </Col>
          <Col md="6" style={{ marginTop: '7px' }}>
            <Flex alignItems="center" justifyContent="between">
              <Form.Label>Area</Form.Label>
            </Flex>
            <SmAreaDropdown
              fieldName="areaId"
              isDisabled={data?.id > 0}
              isMulti={false}
              selectedOptions={data?.areaId ?? ""}
              handleChange={handleChange}
              placeholder="Area"
            />
          </Col>
          <Col md="6" style={{ marginTop: '7px' }}>
            <Flex alignItems="center" justifyContent="between">
              <Form.Label>Skill Level</Form.Label>
            </Flex>
            <SmSkillLevelDropdown
              required
              isDisabled={data?.id > 0}
              selectedOptions={data?.skillLevelId}
              handleChange={handleChange}
            />
          </Col>
          <Col md="6" style={{ marginTop: '7px' }}>
            <Flex alignItems="center" justifyContent="between">
              <Form.Label>Skill Activity</Form.Label>
            </Flex>
            <SmSkillActivityDropdown
              required
              isDisabled={data?.id > 0}
              selectedOptions={data?.skillActivityId ?? ""}
              handleChange={handleChange}
            />
          </Col>
          <Col md="6" style={{ marginTop: '7px' }}>
            <DepartmentDropdown
              label="Departments"
              fieldName="departments"
              closeMenuOnSelect={false}
              isMulti
              options={departments}
              placeholder="Department"
              handleChange={handleChange}
              value={data?.departments}
            />
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer>
        <hr />
        <div className="text-center mt-2">
          <Button color="primary" onClick={handleSubmit}>
            Search
          </Button>
          <Button style={{backgroundColor:'grey', border: 'solid 1px grey', marginLeft: '5px'}}onClick={handleClear}>
            Clear
          </Button>
        </div>
      </Card.Footer>
    </Card>
  );
};
TraningRecordFilter.propTypes = {
  formData: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleClear: PropTypes.func
};

export default TraningRecordFilter;
