import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { BaseTableRQ } from 'components/common/models/BaseClass';
import { search as searchTrainingRecord } from 'services/skill-matric-services/smTrainingRecordService';
import TrainingRecordTable from './TrainingRecordTable';
import TrainingRecordTableHeader from './TrainingRecordTableHeader';
import TraningRecordFilter from './TraningRecordFilter';
import PropTypes from 'prop-types';
import { joinDataMultiDropdown } from 'helpers/dropdownFuncHelper';

const RunningTrainingRecord = ({ meetingId }) => {
  const defaultRQ = { ...new BaseTableRQ(1, 5), meetingId: meetingId };
  const [trainingRecords, setTrainingRecords] = useState([]);

  const [total, setTotal] = useState(0);
  const [paging, setPaging] = useState({ ...defaultRQ, meetingId: meetingId });
  const [isFilter, setIsFilter] = useState(false);
  const [filter, setFilter] = useState({});

  useEffect(() => {
    setIsFilter(false);
  }, []);

  useEffect(() => {
    loadTrainingRecords({...paging});
  }, []);
  
  const handleSubmit = () => {
    let rq = mapRQData(filter);
    loadTrainingRecords({ ...defaultRQ, ...rq });
  };

  const mapRQData = data => {
    console.log(data)
    if (data) {
      let mapRq = { ...data };
      if (data?.startDate) {
        mapRq.startDate = data?.startDate?.toISOString();
      }

      mapRq.areaId = data?.areaId?.id;
      mapRq.sectionId= data?.sectionId?.id;
      mapRq.departments = joinDataMultiDropdown(data?.departments);
      mapRq.skillActivityId = data?.skillActivityId?.id;
      mapRq.skillLevelId =  data?.skillLevelId?.id;
      mapRq.traineeIds = joinDataMultiDropdown(data?.trainee);
      return mapRq;
    }
    return null;
  };

  const gotoPage = index => {
    index += 1;
    if (index <= 0) return;
    paging.pageNum = index;
    loadTrainingRecords(paging);
  };

  const nextPage = () => {
    const page = paging.pageNum + 1;
    if (page > Math.ceil(total / paging.pageSize)) return;
    paging.pageNum = page;
    loadTrainingRecords(paging);
  };

  const previousPage = () => {
    const page = paging.pageNum - 1;
    if (page <= 0) return;
    paging.pageNum = page;
    loadTrainingRecords(paging);
  };

  

  const loadTrainingRecords = pagingRq => {
    searchTrainingRecord(pagingRq)
      .then(res => {
        if (res) {
          setTrainingRecords(res.items);
          setTotal(res.total);
        }
      })
      .catch(err => {
        console.log(err);
      });
    setPaging(pagingRq);
  };

  const toggelFilter = () => {
    setIsFilter(!isFilter);
    setFilter({})
  }

  const handleChange = (field, value) => {
    console.log(field,value)
    setFilter({
      ...filter,
      [field]: value
    });
  };

  const handleClear = () => {
    setFilter(undefined)
  }

  return (
    <Card>
      <Row style={{ padding: '10px' }}>
        <TrainingRecordTableHeader
          style={{ padding: '10px' }}
          toggelFilter={toggelFilter}
        />
        <Col lg={isFilter ? 9 : 12}>
          <TrainingRecordTable
            data={trainingRecords}
            paging={paging}
            total={total}
            nextPage={nextPage}
            previousPage={previousPage}
            gotoPage={gotoPage}
          />
        </Col>
        {isFilter && (
          <Col lg={3}>
            <TraningRecordFilter
              handleChange={handleChange}
              formData={filter}
              handleSubmit={handleSubmit}
              handleClear={handleClear}
            />
          </Col>
        )}
      </Row>
    </Card>
  );
};

RunningTrainingRecord.propTypes = {
  meetingId: PropTypes.string
};

export default RunningTrainingRecord;
