import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PartialTable from 'components/partials/stock-location/PartialTable';
import { Link } from 'react-router-dom';
import UsersInCellWidget from 'components/common/users/UsersInCellWidget';
import moment from 'moment';
import DepartmentComponent from './DepartmentComponent';

const WrapTrainingDetailLink = (id, content) => {
  return (
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      to={`/hr/skill-matrix/section/skills/core-training-record?id=` + id}
    >
      {content}
    </Link>
  );
};
const TrainingRecordTable = ({
  data,
  paging,
  total,
  nextPage,
  previousPage,
  gotoPage,
  onPageSizeChange
}) => {

  const [columns] = useState([
    {
      accessor: 'id',
      Header: 'ID',
      isVisible: false
    },
    {
      accessor: 'trainee',
      Header: 'Trainee',
      Cell: rowData => {
        const { id, trainee } = rowData.row.original;
        return WrapTrainingDetailLink(
          id,
          <UsersInCellWidget usersText={trainee?.toString()} />
        );
      }
    },
    {
      accessor: 'sectionName',
      Header: 'Section',
      isVisible: true,
      Cell: rowData => {
        const { id, sectionName } = rowData.row.original;
        return WrapTrainingDetailLink(id, sectionName);
      }
    },
    {
      accessor: 'skillActivityName',
      Header: 'Skill Activity',
      isVisible: true,
      Cell: rowData => {
        const { id, skillActivityName } = rowData.row.original;
        return WrapTrainingDetailLink(id, skillActivityName);
      }
    },
    {
      accessor: 'skillLevelName',
      Header: 'Skill Level',
      isVisible: true,
      Cell: rowData => {
        const { id, skillLevelName } = rowData.row.original;
        return WrapTrainingDetailLink(id, skillLevelName);
      }
    },
    {
      accessor: 'areaName',
      Header: 'Area',
      isVisible: true,
      Cell: rowData => {
        const { id, areaName } = rowData.row.original;
        return WrapTrainingDetailLink(id, areaName);
      }
    },
    {
      accessor: 'comment',
      Header: 'Comment',
      isVisible: true,
      Cell: rowData => {
        const { id, comment } = rowData.row.original;
        return WrapTrainingDetailLink(id, comment);
      }
    },
    {
      accessor: 'department',
      Header: 'Department',
      isVisible: true,
      Cell: rowData => {
        const { id, department } = rowData.row.original;
        return WrapTrainingDetailLink(
          id,
          <DepartmentComponent departmentText={department?.toString()} />
        );
      }
    },
    {
      accessor: 'managerSignature',
      Header: 'Manager Signature',
      isVisible: false
    },
    {
      accessor: 'managerSignatureDate',
      Header: 'Manager Signature Date',
      isVisible: false
    },

    {
      accessor: 'startDate',
      Header: 'Start Date',
      isVisible: true,
      Cell: rowData => {
        const { startDate } = rowData.row.original;
        return moment(startDate).format('DD/MM/yyyy');
      }
    },
    {
      accessor: 'supervisorSignature',
      Header: 'Supervisor Signature',
      isVisible: false
    },
    {
      accessor: 'supervisorSignatureDate',
      Header: 'Supervisor Signature Date',
      isVisible: false
    },
    {
      accessor: 'traineeSignature',
      Header: 'Trainee Signature',
      isVisible: false
    },
    {
      accessor: 'traineeSignatureDate',
      Header: 'Trainee Signature Date',
      isVisible: false
    },
    {
      accessor: 'trainerSignature',
      Header: 'Trainer Signature',
      isVisible: false
    },
    {
      accessor: 'trainerSignatureDate',
      Header: 'Trainer Signature Date',
      isVisible: false
    },
    {
      accessor: 'trainingOfficer',
      Header: 'Training Officer',
      isVisible: false
    },
    {
      accessor: 'trainingOfficerDate',
      Header: 'Training Officer Date',
      isVisible: false
    }
  ]);

  return (
    <>
      <PartialTable
        data={data}
        columns={columns}
        paging={paging}
        total={total}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
        onPageSizeChange={onPageSizeChange}
        textAlign=""
      />
    </>
  );
};

TrainingRecordTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  paging: PropTypes.object,
  total: PropTypes.number,
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
  gotoPage: PropTypes.func,
  onPageSizeChange: PropTypes.func
};

export default TrainingRecordTable;
